import React from "react"
// import {useState} from 'react'
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Slider = ({en = false}) => {
  const data = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "carousel/hero-7.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <section className="hero">
      <Img fluid={data.background.childImageSharp.fluid} className="background" />
      <div className="container-md h-100 d-flex flex-column justify-content-end pt-4">
        <div className="row">
          <div className="p-5 m-1 m-sm-0 bg-transparent col-lg-6 col-md-10 col-sm-12 d-flex flex-column justify-content-center align-items-start animated bounceInLeft">
            {/* <h2 className="text-white tag">Logistics service</h2> */}
            <p className="h1 text-white mb-4 animated bounceInLeft">Pioneer Solutions is a trusted importer</p>
            <p className="text-white">Pioneer solution’s network covers the entire Americas, Greater China, and extensively in Southeast Asia.</p>
            <div className="mt-3">
              <Link to="/contact-us" className="btn btn-lg btn-light shadow">
                Contact us
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Slider
