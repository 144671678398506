import React from "react"

import { Link, useStaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image'

import Layout from "../components/layout"
import SEO from "../components/seo"
import Carousel from "../components/carousel"
import SectionImage from "../components/section-image"

const EnglishPage = () => {

  const data = useStaticQuery(graphql`
    query {
      about: file(relativePath: { eq: "port.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
              ...GatsbyImageSharpFluid
          }
        }
      }
      bajacargo: file(relativePath: { eq: "services/aduanal.png" }) {
        childImageSharp {
          fluid(quality: 75) {
              ...GatsbyImageSharpFluid
          }
        }
      }
      parallax: file(relativePath: { eq: "carousel/hero-2.png" }) {
        childImageSharp {
          fluid(quality: 75) {
              ...GatsbyImageSharpFluid
          }
        }
      }
      maritimo: file(relativePath: { eq: "services/maritim.jpg" }) {
        childImageSharp {
          fluid(quality: 75) {
              ...GatsbyImageSharpFluid
          }
        }
      }
      brokerage: file(relativePath: { eq: "services/brokerage.jpg" }) {
        childImageSharp {
          fluid(quality: 75) {
              ...GatsbyImageSharpFluid
          }
        }
      }
      trailer: file(relativePath: { eq: "services/trailer.jpg" }) {
        childImageSharp {
          fluid(quality: 75) {
              ...GatsbyImageSharpFluid
          }
        }
      }
      atorigin: file(relativePath: { eq: "services/at-origin.jpg" }) {
        childImageSharp {
          fluid(quality: 75) {
              ...GatsbyImageSharpFluid
          }
        }
      }
      trains: file(relativePath: { eq: "services/trains.jpg" }) {
        childImageSharp {
          fluid(quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      warehouse: file(relativePath: { eq: "services/warehouse.jpg" }) {
        childImageSharp {
          fluid(quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      specialcargo: file(relativePath: { eq: "services/special-cargo.jpg" }) {
        childImageSharp {
          fluid(quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return(

    <Layout en={true}>
      <SEO title="Logistics solutions" />

      <Carousel en={true} />

      <section className="container-md py-5 my-5 rounded">
        <div className="d-flex flex-column align-items-center">
          <h2 className="text-dark pb-3 text-center text-center">Team of  <br /> logistics professionals</h2>
          <p className="text-center pb-4">Founded in 2016 by a team of logistics professionals with a true passion for international trade, <b>Pioneer Solution’s mission has always been to be the preferred freight forwarder serving and facilitating the logistics needs</b> of other entrepreneurs and <b>helping them realize their business’s full potential on a global scale</b>.</p>
          <Link to="/about-us" className="btn btn-outline-info rounded">Read more here</Link>
        </div>
      </section>
      
      <section className="container-md my-5 py-5">
        <div className="pb-5 mb-5">
          <Img fluid={data.about.childImageSharp.fluid} className="rounded h-400" alt="about us" />
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <h6 className="tag h5 pb-4">Why Pioneer Solutions</h6>
            <h3 className="h1 mb-0">At Pioneer Solutions <br /> we treat all shipments <br /> with importance <br />and urgency</h3>
          </div>

          <div className="col-sm-12 col-md-6 d-flex flex-column align-items-start justify-content-start">
            <div className="pt-3 pl-md-5 mt-45">
              <p className="pb-3">We take a personal approach to our customers’ success and aim to be their <b>trusted logistics partner</b>. We remain committed to our founding philosophy of superior customer service and putting our customers first.</p>
              <Link to="/about-us" className="p-0 text-info">I want know more</Link>
            </div>
          </div>
        </div>
      </section>

      <Img fluid={data.parallax.childImageSharp.fluid} className="bg-parallax h-350" alt="Pioneer solutions" />

      <section className="container-md my-5 py-5">
        <div className="row">
        
          <div className="col-lg-4 col-md-6 d-flex flex-column align-items-start justify-content-center">
            <h2 className="text-dark pb-4">The Pioneer Difference</h2>
            <p className="pb-5 mb-5">Our mission is to provide solutions to our clients while offering exceptional service and setting the standard for professionalism in the logistics industry.</p>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="px-0">
              <Img fluid={data.maritimo.childImageSharp.fluid} className="w-100 h-200 rounded" alt="Ocean import and export" />
              <div className="py-4 mb-4 mb-4">
                <h4 className="text-dark pb-3">Ocean Import-Export</h4>
                <p className="pb-3">Pioneer Solutions works closely with the leading shipping lines such as APL, COSCO, CMA CGM, ONE, Hyundai, Yang Ming, MSC, and Maersk in order to provide our customers with the best pricing and equipment availability.</p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="px-0">
              <Img fluid={data.atorigin.childImageSharp.fluid} className="w-100 h-200 rounded" alt="Comprehensive Services at Origin" />
              <div className="py-4 mb-4">
                <h4 className="text-dark pb-3">Comprehensive Services at Origin</h4>
                <p>For transshipment cargo, our highly trained customer support staff will actively track cargo until the shipments arrive at the final destination.</p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="px-0">
              <Img fluid={data.trains.childImageSharp.fluid} className="w-100 h-200 rounded" alt="Superior Destination Port Service" />
              <div className="py-4 mb-4">
                <h4 className="text-dark pb-3">Superior Destination Port Service</h4>
                <p>We have extensive experience in handling DDU, DDP, LDP cargoes and handle distributions, whether through our warehouse, trucks, or rail.</p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="px-0">
              <Img fluid={data.specialcargo.childImageSharp.fluid} className="w-100 h-200 rounded" alt="Special Cargo" />
              <div className="py-4 mb-4">
                <h4 className="text-dark pb-3">Special Cargo</h4>
                <p>We also have vast experience in handling personal effects, DG cargo, reefer containers, exhibition samples, and oversized / overweight cargo and ensuring their smooth customs declaration and clearance process.</p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="px-0">
              <Img fluid={data.brokerage.childImageSharp.fluid} className="w-100 h-200 rounded" alt="Customs Brokerage & Trade Advisory" />
              <div className="py-4 mb-4">
                <h4 className="text-dark pb-3">Customs Brokerage & Trade Advisory</h4>
                <p>Pioneer Solutions is a trusted importer with vast experiences in navigating U.S. and import regulations.</p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="px-0">
              <Img fluid={data.trailer.childImageSharp.fluid} className="w-100 h-200 rounded" alt="Trucking & Inland Transportation" />
              <div className="py-4 mb-4">
                <h4 className="text-dark pb-3">Trucking & Inland Transportation</h4>
                <p>At Pioneer Solutions, we understand trucking and drayage is frequently a pain and bottleneck for supply chains.</p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="px-0">
              <Img fluid={data.warehouse.childImageSharp.fluid} className="w-100 h-200 rounded" alt="Warehouse and Distribution" />
              <div className="py-4 mb-4">
                <h4 className="text-dark pb-3">Warehouse and Distribution</h4>
                <p>Warehousing is key to supply chain management. We can service the entire United States from our network of partner warehouses across main U.S. Ports.</p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 py-4 mb-4 d-flex flex-column align-items-center justify-content-center">
            <div>
              <h6 className="tag pb-1 text-dark">Our services</h6>
              <h3 className="h2 pb-3">Experience and Relationships</h3>
              <Link to="/services" className="btn btn-info ronded btn-lg">Go to services</Link>
            </div>
          </div>

        </div>
      </section>

      <section className="py-4 bg-white border-top">
        <SectionImage 
          reverse={true}
          tag="Exports and imports"
          title="Baja Cargo Solutions"
          paragraph={["Moving cargo via ocean vessel is a timeless tradition that has evolved into the main transportation mode of global trade. Baja Cargo Solutions offers tailor made ocean freight and Intermodal services based on your shipment needs. Upon receiving your shipment information, we determine the best shipping method to meet your goals."]}
          src={<Img fluid={data.bajacargo.childImageSharp.fluid} className="rounded h-100" alt="Exportaciones e importaciones" />}
          link={{to: "http://bajacargosolutions.com/", title: "Visit web site"}}
        />
      </section>

    </Layout>
  )
}

export default EnglishPage
